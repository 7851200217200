import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const MarketingContext = createContext(initialstate);
MarketingContext.displayName = "MarketingContext";

export default MarketingContext;
